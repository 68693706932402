<template>
    <div class="main-wrapper">
        <div class="teacher-top">
            <div class="top-left">
                <span class="left-title">学生管理</span>
            </div>
            <div class="top-right">
                <el-button @click="downloadTmpl">下载学生模板</el-button>
                <div class="import-tmpl-content">
                    <input type="file" name="" class="tmpl-btn" id="" ref="tmpl" @change="importTmpl">
                    <el-button type="primary">导入学生数据</el-button>
                </div>
                <el-button type="primary" @click="addStudent()">创建学生</el-button>
            </div>
        </div>
        <div class="major-screening">
            <span class="major-screening-title">条件筛选:</span>
            <el-cascader v-model="classValue" style="width: 200px" :props="keyObj" :options="conditionList" @change="getTags" clearable></el-cascader>
        </div>
        <el-table :data="studentTable" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="学生名称" align="left"></el-table-column>
            <el-table-column prop="username" label="账号" align="center"></el-table-column>
            <el-table-column prop="class_name" label="班级" align="center"></el-table-column>
            <el-table-column prop="head_portrait" label="头像" align="center">
                <template width="32" slot-scope="scope" class="avatar">
                    <img :src="scope.row.head_portrait?scope.row.head_portrait:require('../../assets/img/image/user_img.png')"/>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="260">
                <template slot-scope="scope">
                    <el-button size="small" @click="editStudent(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteStudent(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                @current-change="studentCurrentChange">
        </el-pagination>
        <el-dialog :title="studentTitleType" :visible.sync="dialogAddStudent" center width="30%" @close="resetForm()"
                   @opened="openAddStudent" :close-on-click-modal="false">
            <el-form :model="addStudentForm" ref="addStudentForm" :rules="rules" label-position="left" class="student-info">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addStudentForm.name" ref="studentInput" autocomplete="off" placeholder="请输入名称" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
                    <el-input v-model="addStudentForm.username" v-if="this.studentTitleType==='创建学生'" autocomplete="off" placeholder="请输入账号" style="width: 80%;"></el-input>
                    <el-input v-model="addStudentForm.username" v-if="this.studentTitleType==='编辑学生'" disabled autocomplete="off" placeholder="请输入账号" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item v-if="this.studentTitleType==='创建学生'" label="密码" :label-width="formLabelWidth" prop="password">
                    <el-input v-model="addStudentForm.password" type="password" autocomplete="off" placeholder="请输入密码" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="班级" :label-width="formLabelWidth" prop="class_id">
                    <el-select v-model="addStudentForm.class_name" placeholder="请选择班级" @change="classSelect($event)" style="width: 80%;">
                        <el-option
                                v-for="item in selectClass"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" prop="image">
                    <template>
                        <input class="up-img" @change="changeFile" type="file" id="id_avatar" name="file" title/>
                        <el-button size="small" type="primary">{{addStudentForm.image?'重新上传':'点击上传'}}</el-button>
                        <img :src="addStudentForm.image" v-show="addStudentForm.image" class="up-img-show" alt/>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" type="primary" @click="addToForm('addStudentForm')">确 定</el-button>
                <el-button size="medium" @click="dialogAddStudent = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {importStuTmpl} from "@/utils/apis";
    export default {
        name: "StudentManage",
        data(){
            return{
                studentTable:[],
                classValue:[],
                selectClass:[],
                addStudentForm: {
                    name: '',
                    username: '',
                    head_portrait: '',
                    password: '',
                    major_id:'',
                    image: '',
                    imageFile: '',
                    class_id:'',
                    class_name:''
                },
                keyObj:{
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                conditionList:[],
                dialogAddStudent: false,
                //弹窗标题
                studentTitleType: '',
                formLabelWidth: '80px',
                //确定/保存
                distinguishBtn: '',
                rules: {
                    name: [
                        {required: true, message: '请输入学生名称', trigger: 'blur'},
                        {min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur'},
                    ],
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在1到20个字符', trigger: 'blur'},
                    ],
                    class_id: [
                        {required: true, message: '请选择班级', trigger: 'change'},
                    ],
                },
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {

            // this.classValue[0] = localStorage.getItem('now_major_id');
            //
            // this.classValue[1] = localStorage.getItem('now_class_id');
            //
            // console.log(this.classValue,55555)


            this.getStudentList();
            this.getSpecializedList();
            this.getCurrentList();

        },
        methods:{
            downloadTmpl() {
                window.open('user/template', '_blank');
            },
            importTmpl() {
                let tmplEle = this.$refs.tmpl.files[0];
                let formdata = new FormData();
                formdata.append('file', tmplEle);
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                });
                importStuTmpl(formdata).then(res => {
                    loading.close()
                    if (res.code === 200) {
                        this.$message({
                            type: "success",
                            message: res.msg,
                            duration: 1500,
                            onClose: () => {
                                this.getStudentList()
                                this.$refs.tmpl.value = ""
                            }
                        });
                    } else {
                        this.$message.warning(res.msg)
                        this.$refs.tmpl.value = "";
                    }
                }).catch(err => {
                    loading.close()
                    console.log('err', err);
                })
            },
            getTags() {
                this.getStudentList();
            },
            // 创建学生
            addStudent(){
                this.dialogAddStudent = !this.dialogAddStudent;
                this.studentTitleType = '创建学生';
                this.distinguishBtn = 'add';
            },
            editStudent(row) {
                this.dialogAddStudent = !this.dialogAddStudent;
                this.studentTitleType = '编辑学生';
                this.distinguishBtn = 'edit';
                this.addStudentForm.id = row.id;
                this.addStudentForm.username = row.username;
                this.addStudentForm.name = row.name;
                this.addStudentForm.password = row.password;
                this.addStudentForm.major_id = row.major_id;
                this.addStudentForm.class_id = row.class_id;
                this.addStudentForm.class_name = row.class_name;
                this.addStudentForm.image = row.head_portrait;
            },
            addOrEdit(imageUrl){
                let formData = new FormData();
                formData.append("name", this.addStudentForm.name);
                formData.append("username", this.addStudentForm.username);
                formData.append("password", this.addStudentForm.password);
                formData.append("major_id", this.addStudentForm.major_id);
                formData.append("class_id", this.addStudentForm.class_id);
                formData.append("head_portrait", imageUrl);
                formData.append("type", 4);
                if (this.distinguishBtn === 'edit') {
                    formData.append("id", this.addStudentForm.id);
                }
                // console.log(formData)
                this.$http.axiosPost(this.$api.getStudent, formData, res => {
                        if (res.code === 200) {
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                                onClose: () => {
                                    this.dialogAddStudent = !this.dialogAddStudent;
                                    this.resetForm();
                                    this.getStudentList();
                                }
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            },
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addStudentForm.imageFile) {
                            let fileOb = new FormData();
                            fileOb.append("file", this.addStudentForm.imageFile);
                            this.$http.axiosPost(this.$api.upload, fileOb, res => {
                                if (res.code === 200) {
                                    this.addOrEdit(res.data)
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.message,
                                        duration: 1000
                                    });
                                }
                            })
                        } else {
                            this.addOrEdit(this.addStudentForm.image)
                        }

                    }
                });
            },
            // 获取学生列表
            getStudentList(){
                let param ={
                    page: this.studentPages.currentPageNum,
                    pageSize: this.studentPages.eachPageNum,
                    paging: 1,
                    type: 4,
                };
                param.major_id = this.classValue[0]
                param.class_id = this.classValue[1]
                this.$http.axiosGetBy(this.$api.getStudent,param, (res) => {
                    if (res.code === 200) {
                        this.studentTable = res.data.data;
                        this.studentPages.total = res.data.total;
                        // localStorage.setItem('now_class_id',res.data.now_class_id);
                        // localStorage.setItem('now_major_id',res.data.now_major_id)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //专业分类
            getSpecializedList() {
                this.$http.axiosGet(this.$api.getMajor, (res) => {
                    if (res.code === 200) {
                        this.getSpecializedListData( res.data.data)
                    }
                })
            },
            getSpecializedListData(data) {
                for (let i = 0;i<data.length;i++){
                    data[i]['children']  = [];
                }
                this.conditionList = data;
                let obj = {};
                obj.id = 0;
                obj.name="全部";
                this.conditionList.unshift(obj)
            },
            // 获取班级列表
            getCurrentList() {
                this.$http.axiosGet(this.$api.getClass, (res) => {
                    if (res.code === 200) {
                        this.selectClass = res.data.data;
                        this.getCurrentListData(res.data.data)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getCurrentListData(data) {
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0;j<this.conditionList.length;j++){
                        if (this.conditionList[j].id == data[i].major_id) {
                            this.conditionList[j].children.push(data[i]);
                            break;
                        }
                    } 
                }

            },
            classSelect(e) {
                // 班级选择
                this.addStudentForm.class_id = e;
                for(let i in this.selectClass){
                    if (this.selectClass[i].id == e) {
                        this.addStudentForm.major_id = this.selectClass[i].major_id;
                        break;
                    }
                }
            },
            // 删除
            deleteStudent(row){
                this.$confirm('是否删除该数据，删除完将无法恢复','提示消息',{
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                    type:'warning',
                    center:true
                }).then(()=>{
                    this.$http.axiosPost(this.$api.delStudent,{id:row.id,type:4},(res) =>{
                        if(res.code === 200){
                            this.$message.success(res.message);
                            this.getStudentList()
                        }else{
                            this.$message.warning(res.message)
                        }
                    },(err) => {
                        console.log(err)
                    })
                }).catch(() =>{
                        this.$message.info('已取消删除')
                    })
                },
            // 头像
            changeFile(e) {
                if (this.beforeAvatarUpload(e.target.files[0])) {
                    this.addStudentForm.imageFile = e.target.files[0];
                    var fr = new FileReader();
                    fr.onloadend = (e) => {
                        this.addStudentForm.image = e.target.result;
                    };
                    fr.readAsDataURL(this.addStudentForm.imageFile);
                } else {
                    document.getElementById("id_avatar").value = "";
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error("上传头像图片只能是 JPG 格式!");
                }
                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isJPG && isLt2M;
            },
            //重置表单
            resetForm() {
                this.$refs.addStudentForm.resetFields();
                for (let key in this.addStudentForm) {
                    this.addStudentForm[key] = "";
                }
            },
            //打开弹窗聚焦输入框
            openAddStudent() {
                this.$refs.studentInput.focus();
            },
            //分页
            studentCurrentChange(val) {
                this.studentPages.currentPageNum = val;
                this.getStudentList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .teacher-top {
        display: flex;
        flex-wrap: wrap;
        padding:10px 20px;
        border-bottom: 1px solid #eaeaea;
        .top-left {
            width: 1%;
            flex: 1;
            .left-title {
                font-size: 14px;
                line-height: 40px;
                font-weight: 500;
            }
        }
        .top-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .import-tmpl-content {
                margin: 0 10px;
                position: relative;
                .tmpl-btn {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }
    }
    .major-screening{
        padding:10px 0 10px 20px;
        .major-screening-title{
            font-size: 14px;
            margin-right:6px;
        }
    }
    img{
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    .up-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 32px;
        opacity: 0;
    }
    .up-img-show{
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
        vertical-align: middle;
        margin-left: 10px;
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding:10px 9px;
    }
</style>